<template lang="pug">
div
  vue-headful(title="Ownmesh charts")
  user-confirm-modal(v-if="landing" ref="user_confirm_modal")
  set-order-modal(ref="chart_order_modal" main_prop="chart")
  data-list-modal(ref="data_list_modal")
  div.root_container
    data-modal(ref="data_modal")
    chart-instance(v-if="selected_data_pk && !landing" :data_pk="selected_data_pk" 
                   :landing="landing")
    template(v-if="landing && !loading" v-for="chart in ordered_chart_map")
      chart-instance(:data_pk="chart.data" :chart_pk="chart.id" :landing="landing"
                     :prop_height="chart.height")
</template>

<script>
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/trash'

import { mapGetters } from 'vuex'
import bus from '@/services/bus'
import CONF from '@/conf'

import Chart_instance from '@/components/Chart_instance.vue'
import Data_modal from '@/components/Data_modal.vue'
import Data_list_modal from '@/components/Data_list_modal.vue'
import Set_order_modal from '@/components/Set_order_modal.vue'
import User_confirm_modal from '@/components/User_confirm_modal.vue'

var orderBy = require('lodash/orderBy')

export default ({
  name: 'Chart_landing',
  components: {
    Icon,
    'chart-instance': Chart_instance,
    'data-modal': Data_modal,
    'set-order-modal': Set_order_modal,
    'user-confirm-modal': User_confirm_modal,
    'data-list-modal': Data_list_modal
  },
  data () {
    return {
      data_modal: null,
      data_list_modal: null,
      selected_data_pk: null,
      landing: false,
      loading: true,
      chart_to_remove: null
    }
  },

  computed: {
    ...mapGetters({
      chart_map: 'get_chart_map',
      chart_landing_state: 'get_chart_landing_state',
      host_landing_state: 'get_host_landing_state'
    }),
    ordered_chart_map: function () {
      return orderBy(this.chart_map, 'position')
    }
  },

  watch: {
    '$route' (unused_to, unused_from) {
      if (this.selected_data_pk) {
        bus.$off(`state/chart/${this.selected_data_pk}`)
        this.selected_data_pk = null
      }
      bus.$off('state/chart_landing')
      this.prepare()
    }
  },

  mounted () {
    this.data_modal = this.$refs['data_modal']
    this.data_list_modal = this.$refs['data_list_modal']
    bus.$on('socket/state', (unused_response) => { this.prepare() })
    bus.$on('show_data_modal', (data_pk) => { this.data_modal.show(data_pk) })
    bus.$on('show_chart_remove_modal', (chart_to_remove) => {
      this.chart_to_remove = chart_to_remove
      const identifier = `chart_${chart_to_remove.pk}`
      this.$refs['user_confirm_modal'].show(identifier, {
        confirm_message: `Remove chart favorite for ${chart_to_remove.name} ?`
      })
      bus.$on(`user_confirm/${identifier}`, (confirm) => {
        console.log(`Got user confirm ${identifier}`)
        bus.$off(`user_confirm/${identifier}`)
        if (confirm === true) this.remove_chart()
        else this.chart_to_remove = null
      })
    })
    this.$socket.connect()
  },

  beforeDestroy () {
    bus.$off('socket/state')
    bus.$off('show_data_modal')
    bus.$off('show_chart_remove_modal')
    bus.$off('add_chart')
  },
  
  methods: {
    remove_chart: function () {
      if (!this.chart_to_remove) return
      let submit = this.$http.post(
        CONF.CHART_REMOVE_URL, { chart_pk: this.chart_to_remove.pk }, CONF.REQUEST_OPTIONS)
      submit.then(
        unused_response => { bus.$emit('new_chart_order', null) },
        response => {
          console.error(`Error POST request: ${response.status} ${response.body.text}`)
          this.$toaster['error'](response.body.text)
        }
      )
    },
    prepare: function () {
      bus.$off('add_favorite_chart')
      bus.$off(`new_chart_order`)
      if (this.$route.params.hasOwnProperty('id')) {
        this.landing = this.$route.params.id === 'landing'
        bus.$emit('route/chart_landing', this.landing)
      }
      if (!this.landing) {
        this.selected_data_pk = parseInt(this.$route.params.id)
        bus.$on(`state/chart/${this.selected_data_pk}`, (response) => {
          bus.$emit(`route/${response}`, null)
        })
        bus.$on('add_chart', () => {
          this.submit()
        })
        return
      }
      bus.$on('add_chart', () => {
        let exclude_map = []
        for (let chart of this.chart_map) exclude_map.push(chart.data)
        this.data_list_modal.show('add_chart', {
          title: `Chart add : select data`, exclude_map: exclude_map})
        bus.$on('data_selection/add_chart', (data_pk) => {
          this.selected_data_pk = data_pk
          this.submit()
          bus.$off('data_selection/add_chart')
        })
      })
      if (this.chart_landing_state) {
        this.loading = false
        bus.$emit('route/ready', null)
        return
      }
      let already_fetched = ''
      if (this.host_landing_state) { already_fetched += 'host,' }
      this.$socket.send('chart_landing', JSON.stringify({already_fetched: already_fetched}))
      bus.$on('state/chart_landing', (state) => {
        if (!state) return
        this.loading = false
        bus.$emit('route/ready', null)
      })
      // bus.$on(`new_chart_order`, () => {
      //   this.$store.commit('reset_chart_map')
      //   this.loading = true
      //   this.$socket.send('chart_landing', JSON.stringify({already_fetched: 'host'}))
      // })
    },

    submit: function () {
      let submit = this.$http.post(
        CONF.CHART_ADD_URL, { data_pk: this.selected_data_pk }, CONF.REQUEST_OPTIONS)
      submit.then(
        unused_response => {
          if (this.chart_landing_state) {
            this.$store.commit('set_chart_landing_state', false)
            // this.$store.commit('reset_chart_map')
          }
          if (!this.landing) this.$router.push('/charts/landing')
        },
        response => {
          console.error(`Error POST request: ${response.status} ${response.body}`)
          this.$toaster['error'](response.body)
        }
      )
    }
  },
})
</script>

<style lang="scss" scoped>
@import '../assets/colors';
@media only screen and (min-width: 0) {
  .root {
    padding: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .root {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }
}

.animation {
  display: block;
  overflow: hidden;
}
</style>
